<template>
  <v-select
    v-model="localSelectedValue"
    :items="selectedItems"
    item-value="value"
    item-text="text"
    single-line
    dense
    solo
    hide-details="auto"
  ></v-select>
</template>

<script>
import { extractValueTextListFromReportModeList } from "@/utils/constants/report";

export default {
  props: {
    selectedValue: {
      type: String
    },
    lbId: {
      type: Number,
      required: true
    },
    reportModeList: {
      type: Array,
      required: true
    }
  },

  model: {
    prop: "selectedValue",
    event: "change"
  },

  data() {
    return {
      localSelectedValue: this.selectedValue
    };
  },

  watch: {
    selectedValue(newVal) {
      this.localSelectedValue = newVal;
    },
    localSelectedValue(newVal) {
      this.$emit("change", newVal);
    }
  },

  computed: {
    selectedItems() {
      return extractValueTextListFromReportModeList(
        this.reportModeList,
        this.lbId
      );
    }
  }
};
</script>
