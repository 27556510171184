<template>
  <div>
    <AppDialog
      v-model="isShowDialog"
      size="large"
      :title="title"
      color="primary"
      :action-text="actionText"
      overflow-height="70vh"
      @confirm="processBulkDownload"
    >
      <AppLoadingSpinner v-model="isLoading" />
      <v-data-table
        :headers="listHeaders"
        :items="caseGroupedList"
        item-key="guid"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.reportMode`]="{ item }">
          <ReportModeSelect
            v-if="item.availableReportModes.length > 1"
            v-model="item.reportMode"
            :lb-id="item.lbId"
            :report-mode-list="item.availableReportModes"
            class="select-medium-width"
          />
        </template>
      </v-data-table>
      <template v-slot:title-right>
        <v-select
          v-if="isDownloadTypeZip"
          class="mr-2 select-small-width"
          v-model="selectedExportFileFormat"
          :items="exportFileFormatList"
          single-line
          outlined
          dense
          hide-details="auto"
        ></v-select>
        <v-select
          v-if="isHavingCoverPage"
          class="select-medium-width"
          v-model="isIncludeReportCover"
          :items="includeReportCoverSelectItems"
          single-line
          outlined
          dense
          hide-details="auto"
        ></v-select>
      </template>
    </AppDialog>
    <AppDialog
      v-model="isExporting"
      title="正在导出..."
      persistent
      cancel-text="关闭"
    >
      <div>请稍候。正在导出...</div>
      <v-progress-linear rounded indeterminate height="6"></v-progress-linear>
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import ReportModeSelect from "@/components/report/ReportModeSelect";
import { mapGetters } from "vuex";
import { reportDownloadTypeDict } from "@/utils/constants/report";
import { fetchLbCaseGuidListForBulkReport } from "@/api/case";
import { bulkSaveReportToZip, bulkSaveReportToSinglePdf } from "@/api/report";
import { fetchCoverPageBody } from "@/api/reportInfo";
import { downloadFile } from "@/utils/download";
import {
  reportModeDict,
  reportExportFormatType
} from "@/utils/constants/report";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    ReportModeSelect
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    downloadType: {
      type: String,
      required: true
    },
    caseGuidList: {
      type: Array
    }
  },

  model: {
    prop: "value",
    event: "change"
  },

  data() {
    return {
      isLoading: false,
      isExporting: false,
      errorMsg: "",
      isShowDialog: this.value,
      title: "",
      actionText: "",
      listHeaders: [
        { text: "量表", value: "lbDispName" },
        { text: "案例数量", value: "caseCount" },
        { text: "导出报告类型", value: "reportMode", sortable: false }
      ],
      caseGroupedList: [],
      exportFileFormatList: [
        { text: "PDF文件", value: reportExportFormatType.pdf.name },
        { text: "WORD文件", value: reportExportFormatType.word.name }
      ],
      selectedExportFileFormat: reportExportFormatType.pdf.name, // 默认下载pdf格式
      // report cover
      isHavingCoverPage: false,
      isIncludeReportCover: false,
      includeReportCoverSelectItems: [
        { text: "包含报告封面", value: true },
        { text: "无报告封面", value: false }
      ]
    };
  },

  watch: {
    value(newVal) {
      this.isShowDialog = newVal;
    },
    async isShowDialog(newVal) {
      this.$emit("change", newVal);
      if (newVal) {
        this.isLoading = true;
        this.applyDialogTitle();
        await this.fetchGroupedCaseList();
        await this.fetchCoverPageHtml();
        this.isLoading = false;
      } else {
        this.caseGroupedList = [];
        this.$emit("closed");
      }
    }
  },

  computed: {
    ...mapGetters({
      nodeGuids: "user/loginNodeGuids"
    }),
    isDownloadTypeZip() {
      return this.downloadType === reportDownloadTypeDict.zip;
    },
    isDownloadTypeMergedPdf() {
      return this.downloadType === reportDownloadTypeDict.mergedPdf;
    },
    reportExportListFromCaseList() {
      let reportExportList = [];
      for (let cag of this.caseGroupedList) {
        let cagWithMode = cag.caseGuidList.map(cGuid => {
          return {
            caseGuid: cGuid,
            reportModeName: cag.reportMode,
            exportFormat: this.selectedExportFileFormat,
            isIncludeCoverPage: this.isIncludeReportCover
          };
        });
        reportExportList.push(...cagWithMode);
      }
      return reportExportList;
    }
  },

  methods: {
    applyDialogTitle() {
      if (this.isDownloadTypeZip) {
        this.title = "批量添加至zip压缩包";
        this.actionText = "压缩并下载";
      } else if (this.isDownloadTypeMergedPdf) {
        this.title = "合并到同一个PDF文件";
        this.actionText = "合并下载";
      }
    },
    async fetchGroupedCaseList() {
      if (this.caseGuidList && this.caseGuidList.length) {
        try {
          let lbCaseGuidList = await fetchLbCaseGuidListForBulkReport(
            this.caseGuidList
          );
          // 设置默认值
          if (lbCaseGuidList) {
            this.caseGroupedList = lbCaseGuidList.map(ca => {
              return {
                lbId: ca.lbId,
                lbDispName: ca.lbDispName,
                caseGuidList: ca.caseGuidList,
                caseCount: ca.caseGuidList ? ca.caseGuidList.length : 0,
                availableReportModes: ca.availableReportModes,
                reportMode: reportModeDict.admin_brief.value
              };
            });
          }
        } catch (err) {
          this.errorMsg = err.message;
        }
      }
    },
    async fetchCoverPageHtml() {
      try {
        let coverPageHtml = await fetchCoverPageBody(this.nodeGuids[0]);
        this.isHavingCoverPage = !!coverPageHtml;
      } catch (err) {
        this.isHavingCoverPage = false;
      }
      // 如果有 cover page，则自动选上
      if (this.isHavingCoverPage) {
        this.isIncludeReportCover = true;
      }
    },
    processBulkDownload() {
      if (this.isDownloadTypeZip) {
        return this.bulkExportToZipFile();
      } else if (this.isDownloadTypeMergedPdf) {
        return this.bulkExportToSinglePdf();
      }
    },
    async bulkExportToZipFile() {
      try {
        this.isExporting = true;
        let downloadPath = await bulkSaveReportToZip(
          this.reportExportListFromCaseList
        );
        downloadFile(downloadPath, "批量报告.zip");
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isExporting = false;
    },
    async bulkExportToSinglePdf() {
      try {
        this.isExporting = true;
        let downloadPath = await bulkSaveReportToSinglePdf(
          this.reportExportListFromCaseList
        );
        downloadFile(downloadPath, "批量合并报告.pdf");
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isExporting = false;
    }
  },

  async created() {
    this.isLoading = true;
    this.applyDialogTitle();
    await this.fetchGroupedCaseList();
    await this.fetchCoverPageHtml();
    this.isLoading = false;
  }
};
</script>

<style lang="scss" scoped>
.select-medium-width {
  max-width: 200px;
}

.select-small-width {
  max-width: 150px;
}
</style>
